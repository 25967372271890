// import React, { useState } from 'react';
// import { Swiper } from "swiper/react";
// import 'swiper/swiper-bundle.min.css'
// import 'swiper/swiper.min.css'
// import SwiperCore, { Autoplay, Pagination } from 'swiper/core'
// import Spinner from '../../Shared/Spinner/Spinner';

// const Reviews = () => {
//     SwiperCore.use([Pagination, Autoplay]);
//     const [reviews] = useState([])
//     return (
//         <section id="testimonial">
//             <h4 className="miniTitle text-center">Gallery</h4>
//             <div className="text-center mb-4">
//                 <h3 className="sectionTitle">See our Logos</h3>
//             </div>
            
//              <div >
//                 <Swiper 
//                     pagination={{ clickable: true }}
//                     slidesPerView={3}
//                     breakpoints={{
//                         320: {
//                             slidesPerView: 1,
//                             spaceBetween: 3,
//                         },
//                         768: {
//                             slidesPerView: 2,
//                             spaceBetween: 10,
//                         },
//                         1024: {
//                             slidesPerView: 3,
//                             spaceBetween: 20,
//                         },
//                     }}
//                     autoplay={{
//                         delay: 2500,
//                         disableOnInteraction: false,
//                     }}
//                     spaceBetween={10}
//                     >
                    
//                     {
//                         reviews.length === 0 ? 
//                             <div className="row container mx-auto" >


    
                                      
//     <div id="carouselExampleIndicators" class="carousel slide" data-bs-ride="carousel">
//   <div class="carousel-indicators">
//     <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
//     <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"></button>
//     <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2" aria-label="Slide 3"></button>
//   </div>
//   <div class="carousel-inner">
//     <div class="carousel-item active">
      
//     <img src="https://picsum.photos/200" class="d-block w-100" alt="Slide 1"/>
//     </div>
//     <div class="carousel-item">
//     <img src="https://picsum.photos/id/237/200/300" class="d-block w-100" alt="Slide 1"/> 
//     </div>
//     <div class="carousel-item">
//     <img src="https://picsum.photos/v2/list?page=2&limit=100" class="d-block w-100" alt="Slide 1"/> 
//     </div>
//   </div>
//   <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
//     <span class="carousel-control-prev-icon" aria-hidden="true"></span>
//     <span class="visually-hidden">Previous</span>
//   </button>
//   <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
//     <span class="carousel-control-next-icon" aria-hidden="true"></span>
//     <span class="visually-hidden">Next</span>
//   </button>
// </div>                   
                                
//                             </div>: 
//                             reviews.map((review, id) => {
//                                 return(
//                                                                     <Spinner/>
//                                 )
//                         })
//                     }
//                 </Swiper>
//             </div> 
//         </section>
//     );
// };

// export default Reviews;


// import React from 'react';
// import Review from './Review';

// const Reviews = () => {
//   const images = [
//     'https://picsum.photos/200/300?grayscale',
//     'https://picsum.photos/seed/picsum/200/300',
//     'https://picsum.photos/id/237/200/300',
//     // Add more image URLs as needed
//   ];

//   return (
//     <div>
//       <h2>Gallery</h2>
//       <Review images={images} />
//     </div>
//   );
// };

// export default Reviews;


import React from 'react';
import { Carousel } from 'react-bootstrap';
import './Reviews.css'; // Import the CSS file containing your styles
import Spinner from '../../Shared/Spinner/Spinner';

const Reviews = () => {
  const images = [
    'https://res.cloudinary.com/dzr1jjjrv/image/upload/fl_preserve_transparency/v1717496265/img1_yicrqt.jpg?_s=public-apps',
    'https://res.cloudinary.com/dzr1jjjrv/image/upload/fl_preserve_transparency/v1717496265/img2_wsnmf9.jpg?_s=public-apps',
    'https://res.cloudinary.com/dzr1jjjrv/image/upload/fl_preserve_transparency/v1717496266/img3_ckccjy.jpg?_s=public-apps',
    'https://res.cloudinary.com/dzr1jjjrv/image/upload/fl_preserve_transparency/v1717496265/img4_qpuh6e.jpg?_s=public-apps',
    'https://res.cloudinary.com/dzr1jjjrv/image/upload/fl_preserve_transparency/v1717496266/img5_vtccdi.jpg?_s=public-apps',
    // Add more image URLs as needed
  ];

  return (
    <div id="testimonial">

       <h4 className="miniTitle text-center">GALLERY</h4>
       <div className="text-center">
                <h5 className="text-center sectionTitle">Our recent work</h5>
            </div>
            {images.length === 0 && <div className="spinner text-center"><Spinner/></div>}
      <Carousel  /* Adjust interval as needed */>
        {images.map((image, index) => (
          <Carousel.Item key={index}>
            <img className="d-block w-100" src={image} alt={`Slide ${index}`} />
          </Carousel.Item>
        ))}
      </Carousel>
    </div>
  );
};

export default Reviews;

