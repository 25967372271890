import { Col, Container, Nav, Row, Tab } from 'react-bootstrap';
import React from 'react';
import PricingCard from './PricingCard';
import './Pricing.css';
import { PricingData } from '../../PricingData';
import Spinner from '../../Shared/Spinner/Spinner';
const Pricing = () => {
    return (
        <section id='pricing' className="pricing">
            <h4 className="miniTitle text-center">Pricing</h4>
            <div className="text-center">
                <h2 className="sectionTitle">CHOOSE PLAN</h2>
            </div>
            <Container>
                <Tab.Container defaultActiveKey="2"> 
                    <Row>
                        <Col md={10} className="mx-auto">
                            <Nav className="pricingNav">
                                <Nav.Item className="priceLink2">
                                    <Nav.Link eventKey="2">
                                    
                                    </Nav.Link>
                                </Nav.Item>
                            </Nav>
                       </Col>
                        <Tab.Content>
                        {
                            
                            PricingData.length === 0 ?
                            <div className="spinner text-center mt-3"><Spinner/></div>:
                            PricingData.map((data) => <PricingCard id={1} data={data} />)
                        }
                        
                        </Tab.Content>
                    </Row>
                </Tab.Container>
            </Container>
        </section>
    );
};

export default Pricing;