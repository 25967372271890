import React, { memo } from 'react';
import { Link } from 'react-router-dom';
import { Col } from 'react-bootstrap';
import { scrollUP } from '../../Shared/ScrollTop/ScrollTop';

const FooterCol = ({ title, menuItems, children }) => {
    return (
        <Col md={6} lg={3} className="footerLink">
            {title && <h5>{title}</h5>}
            {menuItems?.map(({ name, id }) => {
                    let to = '/';
                    if (id === 'terms-and-conditions') {
                        to = '/terms-and-conditions';
                    } else if (id === 'Blog') {
                        to = '/blog';
                    }else if (id === 'privacypolicy') {
                        to = '/privacypolicy';
                    }

                    // Add this line

                    return (
                        <li key={id}>
                            <Link to={to} onClick={scrollUP}>
                                {name}
                            </Link>
                        </li>
                    );
                })}
            {children}
        </Col>
    );
};

export default memo(FooterCol);
