import React, { memo } from 'react';
import { Col, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faInstagram,faWhatsapp} from '@fortawesome/free-brands-svg-icons'
import FooterCol from './FooterCol';
import './Footer.css';
import { usefulLink, ourServices, otherLinks, footerInfo } from '../../FooterData';
import FooterInfo from './FooterInfo';
import { scrollUP } from '../../Shared/ScrollTop/ScrollTop';
//this is final
const Footer = () => {
    return (
        <section className='row footer'>
            <Row className="col-md-11 mx-auto">
                <Row className="align-items-center footerInfo">
                    {footerInfo.map(data => <FooterInfo data={data} key={data.id} />)}
                </Row>
                <Col md={6} lg={3} className="fAboutUs">
                    <h5>ABOUT US</h5>
                    <span className="animate-border"></span>
                    <p className="aboutUsDes">
                        At Calvium Logoz, we create unique, memorable logos that capture your brand's essence. Trust our expert designers to bring your vision to life and make your logo stand out.
                    </p>
                    <ul className="socialIcons">
                        <li>
                            <a href="https://www.facebook.com/Uniquelogoz1" onClick={scrollUP} target="_blank" rel="noopener noreferrer">
                                <FontAwesomeIcon icon={faFacebook} />
                            </a>
                        </li>
                        <li>
                            <a href="https://www.instagram.com/calviumlogoz/" onClick={scrollUP} target="_blank" rel="noopener noreferrer">
                                <FontAwesomeIcon icon={faInstagram} />
                            </a>
                        </li>
                        <li>
                            <a href="https://wa.me/918799627311"  target="_blank" rel="noopener noreferrer" to="/">
                                <FontAwesomeIcon icon={faWhatsapp}/>
                                </a>
                        </li> 
                    </ul>
                </Col>
                <FooterCol key="2" menuItems={usefulLink} title="USEFUL LINK" />
                <FooterCol key="3" menuItems={ourServices} title="OUR SERVICES" />
                <FooterCol key="4" menuItems={otherLinks} title="OTHER LINKS" />
            </Row>
            <p className="copyRight">
                Copyright &copy; 2024 <span className="fHighlight">Calvium Logoz</span>. All rights reserved.
            </p>
        </section>
    );
};

export default memo(Footer);
