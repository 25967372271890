import React, { memo } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/system';

const data = [
    {
        title: 'GRAPHIC DESIGN',
        subtitle:
            'Quality graphic design is essential to attracting new customers and standing out from competitors. Our design teams can create eye-catching logo design and marketing assets for every physical and digital touchpoint of your brand experience, from packaging, to in-store displays, to websites.',
        icon: './images/grd.png',
    },
    {
        title: 'SOCIAL MEDIA MARKETING',
        subtitle:
            'Social media marketing is a powerful tool for businesses to reach prospects and customers. People discover, learn about, follow, and shop from brands on social media. If you’re not on platforms like Facebook, Instagram, and LinkedIn, you’re missing out! Effective social media marketing can bring remarkable success to your business, creating devoted brand advocates and driving leads and sales.',
        icon: './images/as_is.png',
    },
    {
        title: 'WEB DEVELOPMENT',
        subtitle:
            'In today’s time being out there is equally important as having a logo. Give your business a genuineness in minds of your customer. Have us create a professional website for your business. We have a wide variety of certified partners and a customer-centric team to manage your projects end-to-end.',
        icon: './images/web.png',
    },
];

const StyledBox = styled(Box)(({ theme }) => ({
    textAlign: 'center',
    padding: theme.spacing(2),
    marginBottom: theme.spacing(4),
}));

const BrowserSupport = () => {
    return (
        <Box p={2} mb={2}>
            <StyledBox>
                <Typography
                    sx={{
                        textTransform: 'uppercase',
                        fontWeight: 'medium',
                        color: 'secondary.main',
                    }}
                    gutterBottom
                >
                    <Typography fontWeight={700} variant="h4">
                        Services
                    </Typography>
                </Typography>
                <Typography fontWeight={500} variant="h4">
                    Compatible with all
                </Typography>
            </StyledBox>
            <Grid container spacing={2}>
                {data.map((item, i) => (
                    <Grid item xs={12} md={4} key={i}>
                        <Box
                            width={1}
                            height={1}
                            data-aos="fade-up"
                            data-aos-delay={i * 100}
                            data-aos-offset={100}
                            data-aos-duration={600}
                        >
                            <Box
                                display="flex"
                                flexDirection="column"
                                alignItems="center"
                            >
                                <Avatar
                                    sx={{ width: 110, height: 110, mb: 2 }}
                                    src={item.icon}
                                    alt={item.title}
                                    loading="lazy"
                                />
                                <Typography
                                    variant="h5"
                                    gutterBottom
                                    fontWeight={600}
                                    align="center"
                                >
                                    {item.title}
                                </Typography>
                                <Typography align="center" color="text.secondary">
                                    {item.subtitle}
                                </Typography>
                            </Box>
                        </Box>
                    </Grid>
                ))}
            </Grid>
        </Box>
    );
};

export default memo(BrowserSupport);
