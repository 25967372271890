import React, { useState, useCallback } from 'react';
import { Col, Row } from 'react-bootstrap';
import './Contact.css';
import swal from 'sweetalert';
import Fade from 'react-reveal/Fade';
import { Suspense } from 'react';
import contactImg from '../../../Assets/contact.svg';

const Contact = () => {
    const [userData, setUserData] = useState({
        Name: "",
        Email: "",
        Subject: "",
        message: "",
    });

    const postUserData = useCallback((event) => {
        const { name, value } = event.target;
        setUserData(prevState => ({ ...prevState, [name]: value }));
    }, []);

    const submitData = useCallback(async (event) => {
        event.preventDefault();
        const { Name, Email, Subject, message } = userData;
        try {
            const response = await fetch("https://logodesign-c6527-default-rtdb.firebaseio.com/Contactus.json", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ Name, Email, Subject, message }),
            });
            if (response.ok) {
                swal("Thank You!", "We appreciate you contacting us!", "success");
                setUserData({ Name: "", Email: "", Subject: "", message: "" });
            } else {
                swal("Error!", "Please fill the form again.", "error");
            }
        } catch (error) {
            console.error("Error:", error);
            swal("Error!", "An error occurred. Please try again.", "error");
        }
    }, [userData]);

    return (
        <section id="contact">
            <Col md={11} className="mx-auto">
                <Row>
                    <Col md={6}>
                        <Fade duration={2000} left>
                            <form method="POST" className="contactForm" onSubmit={submitData}>
                                <h4 className="miniTitle">CONTACT US</h4>
                                <h5 className="sectionTitle">GET IN TOUCH</h5>
                                <Row>
                                    <Col md={12} lg={6}>
                                        <input
                                            placeholder="Your Name"
                                            type="text"
                                            name="Name"
                                            value={userData.Name}
                                            onChange={postUserData}
                                            required
                                        />
                                    </Col>
                                    <Col md={12} lg={6}>
                                        <input
                                            placeholder="Your Email"
                                            name="Email"
                                            type="email"
                                            value={userData.Email}
                                            onChange={postUserData}
                                            required
                                        />
                                    </Col>
                                    <Col md={12}>
                                        <input
                                            placeholder="Subject"
                                            name="Subject"
                                            type="text"
                                            value={userData.Subject}
                                            onChange={postUserData}
                                            required
                                        />
                                    </Col>
                                    <Col md={12}>
                                        <textarea
                                            placeholder="Your Message..."
                                            name="message"
                                            value={userData.message}
                                            onChange={postUserData}
                                            required
                                        ></textarea>
                                    </Col>
                                </Row>
                                <button className="branBtn" type="submit" onClick={submitData}>Submit Now</button>
                            </form>
                        </Fade>
                    </Col>
                    <Col md={6}>
                        <Fade duration={2000} right>
                            <Suspense fallback={<div>Loading...</div>}>
                                <img src={contactImg} alt="Contact Us" className="img-fluid" />
                            </Suspense>
                        </Fade>
                    </Col>
                </Row>
            </Col>
        </section>
    );
};

export default React.memo(Contact);
