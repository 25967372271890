import React from 'react';
import teamPic from '../../../Assets/about.svg';
import Fade from 'react-reveal/Fade';
import styles from './About.module.css';

const About = () => {
    return (
        <section id='aboutus' className={`${styles.about} overflow-hidden py-5`}>
            <div className="row w-100">
                <div className="row col-md-11 mx-auto">
                    <div className="col-md-6 img">
                        <Fade duration={2000} left>
                            <img src={teamPic} alt="Our team working together" className="img-fluid" loading="lazy" />
                        </Fade>
                    </div>
                    <div className="col-md-6 ps-2">
                        <Fade duration={2000} right>
                            <p className={styles.miniTitle}>About Us</p>
                            <h1 className={styles.headerTitle}>
                                HOW WE CAN HELP YOUR <span className={styles.headerHighlight}>BUSINESS</span> LOGO
                            </h1>
                            <p className={styles.headerContent}>
                                At Calvium Logoz, we specialize in creating unique, memorable logos that capture
                                the essence of your brand. Our expert designers work closely with you to bring your vision
                                to life, ensuring your logo stands out in any market. Trust us to design the perfect symbol of your
                                business identity.
                            </p>
                        </Fade>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default About;
