import React from 'react';

const PrivacyPolicy = () => {
  return (
    <div style={styles.container}>
      <h1 style={styles.header}>Privacy Policy</h1>
      
      <section style={styles.section}>
        <h2 style={styles.subHeader}>General</h2>
        <p style={styles.paragraph}>
          At Calviumlogoz, we believe in confidentiality and security of your information. We completely respect your privacy rights. We take different types of measures to protect your information from disclosure to or access by third parties. Please note that we do not guarantee the security of information, as no method of internet transmission or storage is completely secure. Also note that we may retain and store your information in our databases and systems even after your project with Calviumlogoz is completed as long as we have a legitimate purpose to do so and in accordance with applicable law.
        </p>
        <p style={styles.paragraph}>
          We only collect your information with your consent or with lawful reasons; we only collect the personal information that is necessary to fulfil the purpose of your interaction with us; we don't sell it to third parties.
        </p>
        <p style={styles.paragraph}>
          We have prepared this Privacy Policy to describe to you our practices regarding the Personal Information we collect from users of our Site at <a href="http://www.calviumlogoz.in" target="_blank" rel="noopener noreferrer" style={styles.link}>www.calviumlogoz.in</a> and Services.
        </p>
      </section>

      <section style={styles.section}>
        <h2 style={styles.subHeader}>Payment</h2>
        <p style={styles.paragraph}>
          For the Design Project, customer needs to pay the 100% advance payment for all the packages.
        </p>
      </section>

      <section style={styles.section}>
        <h2 style={styles.subHeader}>Refunds</h2>
        <p style={styles.paragraph}>
          All the payments made to Calviumlogoz are completely nonrefundable. We believe in quality work towards any design task and we give our best to satisfy the clients from our end.
        </p>
        <p style={styles.paragraph}>
          No cancellations & Refunds are entertained.
        </p>
      </section>
    </div>
  );
}

const styles = {
  container: {
    padding: '20px',
    fontFamily: 'Arial, sans-serif',
    maxWidth: '800px',
    margin: '0 auto',
    backgroundColor: '#f9f9f9',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    borderRadius: '8px',
    '@media (max-width: 600px)': {
      padding: '10px',
    },
  },
  header: {
    fontSize: '24px',
    textAlign: 'center',
    marginBottom: '20px',
    color: '#333',
    '@media (max-width: 600px)': {
      fontSize: '20px',
    },
  },
  section: {
    marginBottom: '20px',
  },
  subHeader: {
    fontSize: '20px',
    marginBottom: '10px',
    color: '#555',
    '@media (max-width: 600px)': {
      fontSize: '18px',
    },
  },
  paragraph: {
    fontSize: '16px',
    lineHeight: '1.5',
    marginBottom: '10px',
    color: '#666',
    '@media (max-width: 600px)': {
      fontSize: '14px',
    },
  },
  link: {
    color: '#1a73e8',
  },
};

export default PrivacyPolicy;
