import React from 'react';
import { Col, Row, Tab } from 'react-bootstrap';
import Fade from 'react-reveal/Fade';

const PricingCard = ({ data, id }) => {
    
    const handlePaynow = (index) => {
        const links = [
            "https://rzp.io/l/HKJZeVKjpn",
            "https://rzp.io/l/2z8TBksY3",
            "https://rzp.io/l/4kfLB2GVM",
            "https://rzp.io/l/4F5t5PY6TR"
        ];
        window.open(links[index], '_blank');
    };

    return (
        <Tab.Pane eventKey={id + 1}>
        <Row>
            {data.map((product, index) => (
                <Col xl={3} lg={4} md={6} sm={12} key={index} className="d-flex justify-content-center">
                    <Fade bottom duration={1800} distance="40px">
                        <div className={`pricingCard glass-effect pricingCard${id + 1}`}>
                            <div className="pricingBox">
                                <h4>{product.title}</h4>
                                <p className="pricePlan">
                                    <span className={`ph${id + 1}`}>₹{product.price}</span>
                                </p>
                                <h5>{product.name}</h5>
                                <p className="planDescription">{product.description}</p>
                                <hr className="divider" />
                            </div>
                            <li>
                                <button onClick={() => handlePaynow(index)} className="btn paynow">Order Now</button>
                            </li>
                        </div>
                    </Fade>
                </Col>
            ))}
        </Row>
    </Tab.Pane>
    );
};

export default PricingCard;
