import Home from "../src/component/Home/Home/Home";
import { Routes, Route } from "react-router-dom";
import React, { createContext } from "react";
import About from "./component/Home/About/About";
import TermsAndConditions from '../src/component/Home/Home/TermsAndConditions';
// import Dashboard from "./component/Dashoboard/Dashboard/Dashboard";
// import LoginModal from "./component/Login/LoginModal";
// import PrivateRoute from "./component/Login/PrivateRoute";
import NotFound from "./component/NotFound";
import Blog from "./component/Home/Footer/blog";
import PrivacyPolicy from "./component/Home/Footer/privacypolicy";
export const UserContext = createContext();

const App = () => {
  return (
    <div>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        {/* <Route path="/login" element={<LoginModal />} /> */}
        {/* <Route
          path="/dashboard/*"
          element={
            <PrivateRoute redirectTo="/login">
              <Dashboard />
            </PrivateRoute>
          }
        /> */}
        <Route path="/blog" element={<Blog/>} />
          <Route path="/terms-and-conditions" element={<TermsAndConditions/>} />
          <Route path="/privacypolicy" element={<PrivacyPolicy/>} />
        <Route path="/success" element={<success />} />
        <Route  path="/cancel" element={<cancle />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </div>
  );
};

export default App;
