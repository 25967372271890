//import { Description } from "@mui/icons-material";

export const PricingData = [
    [
        {title: 'LOGO DESIGN',description:'3 unique and premium logo samples limited modification in final version 24/7 support CDR, JPG, PNG, PDF all source files 3-4 days delivery time', price: 799},
        {title: 'LOGO DESIGN + INTRO VIDEO',description:'3 unique and premium logo samples with 2 intro video samples limited modification in final version 24/7 support CDR, JPG, PNG, PDF all source files 3-4 days delivery time', price: 999},
        {title: 'BUSINESS PACKAGE',description:'3 unique and premium logo samples with 2 intro videos,1 visiting card sample,1 letterhead sample. free of costs modification in final version 24/7 support CDR, JPG, PNG, PDF all source files 3-4 days delivery time', price: 1999},
        {title: 'ALL INE ONE PACKAGE',description:'3 unique and premium logo samples with 2 intro videos,1 visiting card sample,1 letterhead sample, 50 Festival posts. free of costs modification in final version 24/7 support CDR, JPG, PNG, PDF all source files 4-5 days delivery time', price: 2999}
    ],
    
]