import React, { useState, useEffect } from 'react';
import './ScrollTop.css';

export const scrollUP = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
}

const ScrollTop = () => {
    const [isTrue, setIsTrue] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 50) {
                setIsTrue(true);
            } else {
                setIsTrue(false);
            }
        };

        window.addEventListener("scroll", handleScroll);
        return () => window.removeEventListener("scroll", handleScroll);
    }, []);

    return (
        <div>
            {isTrue && (
                <button onClick={scrollUP} className="scrollBtn">
                    {/* <FontAwesomeIcon icon={faAngleUp} /> */}
                    Button
                </button>
            )}
        </div>
    );
};

export default ScrollTop;
