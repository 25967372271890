import React, { memo } from 'react';
import CountUp from 'react-countup';
import styles from './HappyClient.module.css';

const HappyClient = () => {
    const images = [
        { src: './images/p1.jpg', alt: 'Happy Client 1' },
        { src: './images/p2.jpg', alt: 'Happy Client 2' },
        { src: './images/p3.jpg', alt: 'Happy Client 3' },
        { src: './images/p4.jpg', alt: 'Happy Client 4' }
    ];

    return (
        <section className={styles.ourValue}>
            <div className="text-center">
                <h3 className={styles.sectionTitle}>Our Happy Clients</h3>
            </div>
            <div className={styles.gridContainer}>
                {images.map((image, index) => (
                    <div className={styles.gridItem} key={index}>
                        <div className={styles.ourValueDetails}>
                            <img
                                src={image.src}
                                className={styles.image}
                                alt={image.alt}
                                loading="lazy"
                            />
                        </div>
                    </div>
                ))}
            </div>
        </section>
    );
};

export default memo(HappyClient);
